@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?i18xve");
  src: url("./fonts/icomoon.eot?i18xve#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?i18xve") format("truetype"),
    url("./fonts/icomoon.woff?i18xve") format("woff"),
    url("./fonts/icomoon.svg?i18xve#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-pinterest:before {
  content: "\e90a";
}
.icon-arrow-up-right:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e908";
}
.icon-map:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-x:before {
  content: "\e907";
}
