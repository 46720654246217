header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  z-index: 9999;
  transition: all 0.4s ease;
}

header.bg {
  background-color: #000;
}

.header-content {
  width: 100%;
  margin: 0 0 0 var(--quadPadding);
  color: #fff;
  display: flex;
  height: var(--headerHeight);
  align-items: center;
}

.header-content img {
  height: calc(var(--headerHeight) * 0.6);
  margin-right: var(--doublePadding);
}

.header-content ul {
  display: flex;
  flex-direction: row;
  gap: var(--quadPadding);
}

.header-content ul li {
  font-size: var(--bodyText);
  font-weight: 700;
}

.header-content ul li:hover {
  cursor: pointer;
  color: var(--primaryColor);
}

.header-content .register {
  margin: 0 var(--quadPadding) 0 0;
}

.header-content .sharing {
  margin: 0 var(--doublePadding) 0 auto;
  display: flex;
  gap: var(--standardPadding);
}

.intro-section {
  background-color: var(--pageColor);
  height: 100vh;
  background-image: url("/public/downtown.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  display: flex;
}

.intro-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.intro-copy {
  flex: initial;
  width: 40%;
  background: #000;
  color: #fff;
  padding: var(--quadPadding);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--doublePadding);
  position: relative;
  z-index: 2;
}

.intro-copy h1 {
  font-size: 4rem;
  line-height: 4.2rem;
}

.intro-copy button {
  margin: 0 auto 0 0;
}

.ticket {
  position: absolute;
  bottom: 10rem;
  left: 40%;
  width: 16vw;
  transform: rotate(-55deg);
  z-index: 6;
}

.intro-banner {
  background-color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 7;
}

.intro-banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--standardPadding);
  padding: var(--quarterPadding) 0;
}

.intro-banner.static {
  position: static;
}

.intro-banner div {
  display: flex;
  gap: var(--standardPadding);
  align-items: center;
  justify-content: center;
}

.intro-banner h6 {
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
}

.intro-banner span {
  display: block;
  font-size: 2rem;
}

.section {
  background-color: var(--pageColor);
  min-height: 50vh;
  padding: var(--quadPadding);
}

.section.dark {
  background-color: #000;
  color: #fff;
}

.schedule-list {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: var(--doublePadding) 0 0;
  gap: var(--doublePadding);
}

.schedule-list:before {
  position: absolute;
  top: 0;
  left: 0.22rem;
  width: 2px;
  height: 100%;
  background-color: #000;
  content: "";
  z-index: 0;
}

.schedule-list li {
  font-size: var(--prgBody);
  display: flex;
  gap: var(--standardPadding);
  align-items: center;
}

.schedule-list li:before {
  content: "";
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  background-color: var(--primaryColor);
  border-radius: 100%;
  position: relative;
  z-index: 1;
}

footer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: var(--quadPadding) var(--quadPadding) 0 var(--quadPadding);
}

.footer-flex {
  display: flex;
}

.footer-flex div:first-of-type {
  flex: 1;
}

.footer-flex div {
  flex: 0.5;
}

.footer-flex div:last-of-type {
  margin: 0 0 0 auto;
  justify-content: flex-end;
  display: flex;
}

.footer-flex ul {
  display: flex;
  gap: var(--doublePadding);
}

.footer-flex ul li {
  font-size: 1rem;
  font-weight: 700;
}

.footer-flex ul li:hover {
  cursor: pointer;
  color: var(--primaryColor);
}

.footer-ticket {
  width: 10rem;
  height: 5rem;
}

.legal {
  font-size: 1rem;
  margin: var(--doublePadding) 0 0;
  padding: 0 0 var(--standardPadding);
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: calc(2 * var(--quadPadding));
  padding: 20vh var(--quadPadding);
  height: auto;
  background-color: #000;
  background: linear-gradient(40deg, #044c98, var(--primaryColor));
}

.photo-container {
  width: 15%;
  height: 0;
  padding-bottom: 18%;
  flex: initial;
  position: relative;
  box-shadow: 5px 20px 50px rgba(1, 1, 1, 0.3);
}

.photo-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}

.mobile-only {
  display: none;
}

.register {
  position: relative;
  width: 200px;
}

.register button {
  background-color: var(--primaryColor);
  padding: var(--quarterPadding) var(--doublePadding);
  border-radius: 15px;
  display: inline-block;
}

.register:hover .register-options {
  display: flex;
}

.register-options {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border: 1px solid var(--primaryColor);
  border-radius: 15px;
  flex-direction: column;
  width: 200px;
  padding: var(--halfPadding);
  display: none;
  z-index: 9999;
}

.register-options a {
  font-size: 1rem;
}

.register-options a img {
  height: 1rem;
  margin: 0 0.2rem 0 0;
}

.register-options a:hover {
  color: var(--primaryColor);
}

/* Mobile */
@media screen and (max-width: 760px) {
  .mobile-only {
    display: block;
  }

  .shs-app {
    overflow: hidden;
  }

  .header-content {
    margin: 0;
  }

  .header-content ul {
    display: none;
  }

  .header-content .register {
    display: none;
  }

  .header-content .sharing {
    display: none;
  }

  .header-content img {
    height: calc(var(--headerHeight) * 0.7);
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .header-content .menu-btn {
    margin: 0 var(--doublePadding) 0 auto;
  }

  .intro-section {
    flex-direction: column;
    height: 100vh;
  }

  .intro-copy {
    width: 100%;
    height: auto;
    padding: calc(1.25 * var(--quadPadding)) var(--doublePadding)
      var(--doublePadding);
    text-align: center;
  }

  .intro-copy .register {
    margin: 0 auto;
  }

  .intro-copy h1 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .intro-copy h2 {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .intro-copy p {
    font-size: 0.9rem;
  }

  .section {
    padding: var(--doublePadding);
  }

  .ticket {
    bottom: 15%;
    left: calc(50% - 16.5vw);
    width: 33vw;
    transform: rotate(-55deg);
  }

  footer {
    flex-direction: column;
    padding: var(--doublePadding);
    text-align: center;
  }

  .footer-flex {
    flex: 1;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-flex div {
    margin: 0 !important;
    justify-content: center !important;
  }

  footer h3 {
    font-size: 1.2rem;
    margin: 0;
  }

  footer h4 {
    font-size: 1rem;
    margin: 0;
  }

  footer ul {
    justify-content: center;
  }

  .legal {
    padding: 0;
  }

  .photo-grid {
    padding: var(--quadPadding) var(--doublePadding);
    row-gap: var(--doublePadding);
  }

  .photo-container {
    width: 46.5%;
    padding-bottom: 48%;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    z-index: 99999;
    padding: var(--doublePadding);
    text-align: center;
  }

  .mobile-menu .sharing {
    margin: 2rem 0;
    gap: 1rem;
    display: flex;
    justify-content: center;
  }

  .mobile-menu .close-btn {
    font-size: 2rem;
    margin: 0 0 0 auto;
    display: block;
  }

  .mobile-menu ul {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem;
  }

  .mobile-menu ul li {
    font-size: 1.5rem;
  }

  .mobile-menu .register {
    margin: 0 auto;
  }
}

/* Mobile landscape */
@media screen and (max-width: 920px) and (orientation: landscape) {
  .mobile-only {
    display: block;
  }

  .shs-app {
    overflow: hidden;
  }

  .header-content {
    margin: 0;
  }

  .header-content ul {
    display: none;
  }

  .header-content stripe-buy-button {
    display: none;
  }

  .header-content img {
    height: calc(var(--headerHeight) * 0.7);
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .header-content .menu-btn {
    margin: 0 var(--doublePadding) 0 auto;
  }

  .intro-section {
    height: 100vh;
  }

  .intro-copy {
    width: 60%;
    height: auto;
    padding: var(--doublePadding);
    text-align: center;
  }

  .intro-copy h1 {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  .intro-copy h2 {
    font-size: 0.9rem;
    line-height: 1rem;
  }

  .intro-copy p {
    font-size: 0.8rem;
  }

  .section {
    padding: var(--doublePadding);
    font-size: 1rem;
  }

  .ticket {
    bottom: 19%;
    left: calc(60% - 6vw);
    width: 12vw;
    transform: rotate(-55deg);
  }

  footer {
    flex-direction: column;
    padding: var(--doublePadding);
    text-align: center;
  }

  .footer-flex {
    flex: 1;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-flex div {
    margin: 0 !important;
    justify-content: center !important;
  }

  footer h3 {
    font-size: 1rem;
    margin: 0;
  }

  footer h4 {
    font-size: 0.9rem;
    margin: 0;
  }

  footer ul {
    justify-content: center;
  }

  .legal {
    padding: 0;
  }

  .photo-grid {
    padding: var(--quadPadding) var(--doublePadding);
    row-gap: var(--doublePadding);
  }

  .photo-container {
    width: 32%;
    padding-bottom: 35%;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    z-index: 99999;
    padding: var(--doublePadding);
    text-align: center;
  }

  .mobile-menu .close-btn {
    font-size: 2rem;
    margin: 0 0 0 auto;
    display: block;
  }

  .mobile-menu ul {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem;
  }

  .mobile-menu ul li {
    font-size: 1.5rem;
  }
}
