@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("./fonts.css");

* {
  box-sizing: border-box;
}

:root {
  --pageColor: #e9e8d4;
  --textColor: #000;
  --textColorDisabled: #4d4d4d;
  --headerTextColor: #000;
  --subheaderTextColor: #333;
  --primaryColor: #8e56f5;
  --greenColor: rgb(109, 236, 109);
  --redColor: rgb(249, 69, 102);
  --quadPadding: clamp(2rem, 0.75vw + 4rem, 6rem);
  --doublePadding: clamp(1rem, 0.75vw + 1.25rem, 3rem);
  --standardPadding: clamp(0.5rem, 0.75vw + 1rem, 1.5rem);
  --halfPadding: clamp(0.25rem, 0.37vw + 1rem, 0.75rem);
  --quarterPadding: clamp(0.125rem, 0.17vw + 1rem, 0.375rem);
  --defaultShadow: rgba(34, 34, 34, 0.1);
  --borderColor: #f2f2f2;
  --primaryFont: "Poppins", sans-serif;
  --headerHeight: 65px;

  /* Font sizing */

  /* buttons */
  --prgButton: 1rem;

  /* body, p */
  --bodyText: clamp(1.15rem, 0.3vw + 1rem, 1.2rem);

  /* subhead */
  --prgSubhead: clamp(1rem, 0.1vw + 1rem, 1.125rem);

  /* h1 */
  --prgH1: clamp(2rem, 1.5vw + 1rem, 2.3rem);

  /* h2 */
  --prgH2: clamp(1.8rem, 1.5vw + 1rem, 2rem);

  /* h3 */
  --prgH3: clamp(1.4rem, 0.5vw + 1rem, 1.5rem);

  /* h4 */
  --prgH4: clamp(1.2rem, 0.5vw + 0.8rem, 1.3rem);

  /* h5 */
  --prgH5: clamp(1.05rem, 0.5vw + 0.5rem, 1.08rem);

  /* End font sizing */
}

html {
  scroll-behavior: smooth;
  width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  color: var(--textColor);
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0 auto;
}

body {
  font-family: var(--primaryFont);
  font-size: 14px;
  font-size: var(--bodyText);
  font-weight: 400;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--pageColor);
}

/* Resets */
a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  padding: 0;
  font-size: var(--bodyText);
  white-space: pre-wrap;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
textarea,
input {
  text-rendering: inherit;
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100%;
  white-space: nowrap;
  font-size: var(--prgButton);
}

input:not(:read-only):focus {
  border-color: var(--brandPrimaryColor) !important;
}

button:disabled {
  opacity: 0.4;
  pointer-events: none;
}

button:not(:disabled):hover {
  cursor: pointer;
  opacity: 0.8;
}

/* End resets */

/* Header font sizing */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: 700;
}

h1 {
  font-size: var(--prgH1);
}

h2 {
  font-size: var(--prgH2);
}

h3 {
  font-size: var(--prgH3);
}

h4 {
  font-size: 0.9rem;
  font-size: clamp(0.9rem, 1.25vw + 1rem, 1.1rem);
  margin: 0 0 var(--standardPadding);
}

h5 {
  font-size: 1rem;
  font-size: clamp(1rem, 1vw + 1rem, 1.22rem);
  margin: 0 0 var(--halfPadding);
}

h6 {
  font-weight: 600;
  font-size: 0.8rem;
  font-size: clamp(0.8rem, 0.5vw + 1rem, 0.95rem);
  margin: 0 0 var(--halfPadding);
}

/* End header font sizing */

/* Font properties */
.no-margin {
  margin: 0;
}

.italic {
  font-style: italic;
}

.secondary-font {
  font-family: var(--secondaryFont);
}

.tertiary-font {
  font-family: var(--tertiaryFont);
}

.color-primary {
  color: var(--brandPrimaryColor);
}

/* Buttons */
.primary-btn {
  border-radius: 20px;
  padding: var(--quarterPadding) var(--standardPadding);
  font-size: var(--prgButton);
  background-color: var(--primaryColor);
  color: #fff;
  font-weight: 500;
}

.underline-btn {
  font-family: var(--primaryFont);
  color: var(--brandPrimaryColor);
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  font-size: var(--prgButton);
}

.mobile-only {
  display: none;
}

/* Error */
.prg-error {
  text-align: center;
  padding: var(--quadPadding) var(--quadPadding) 0;
}

.prg-error p {
  margin: var(--doublePadding) 0;
}

.prg-error a {
  color: var(--brandPrimaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  :root {
    --headerHeight: 60px;

    /* Font sizing */

    /* buttons */
    --prgButton: 1rem;

    /* body, p */
    --bodyText: clamp(1.2rem, 0.3vw + 1rem, 1.25rem);

    /* subhead */
    --prgSubhead: clamp(1.063rem, 0.1vw + 1rem, 1.125rem);

    /* h1 */
    --prgH1: clamp(2.4rem, 1.5vw + 1rem, 2.5rem);

    /* h2 */
    --prgH2: clamp(1.9rem, 1.5vw + 1rem, 2rem);

    /* h3 */
    --prgH3: clamp(1.4rem, 0.5vw + 1rem, 1.5rem);

    /* h4 */
    --prgH4: clamp(1.2rem, 0.5vw + 1rem, 1.3rem);

    /* h5 */
    --prgH5: clamp(0.9rem, 0.5vw + 0.6rem, 1rem);

    /* End font sizing */
  }

  .mobile-only {
    display: block;
  }

  .prg-primary-btn {
    white-space: nowrap;
  }
}

/* Mobile: Landscape */
@media screen and (max-width: 860px) {
  :root {
    /* h1 */
    --prgH1: clamp(2rem, 1vw + 1rem, 2.1rem);

    /* h2 */
    --prgH2: clamp(1.5rem, 1vw + 1rem, 1.6rem);

    /* h3 */
    --prgH3: clamp(1rem, 0.5vw + 1rem, 1.1rem);

    /* h4 */
    --prgH4: clamp(0.95rem, 0.2vw + 1rem, 1rem);

    /* h5 */
    --prgH5: clamp(0.95rem, 0.5vw + 0.6rem, 1rem);
  }
}
